module services {
    export module documentManagement {
        export class documentRepositoryFileService implements interfaces.documentManagement.IDocumentRepositoryFileService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDocumentRepositoryFile(): ng.resource.IResourceClass<interfaces.documentManagement.IDocumentRepositoryFile> {

                return this.$resource<interfaces.documentManagement.IDocumentRepositoryFile>(this.ENV.DSP_URL + "DocumentRepositoryFile/GetDocumentRepositoryFile", {
                    Key: '@Key'
                }, {
                        query: {
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            method: 'GET',
                            isArray: false
                        }
                    });
            }
            
            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DocumentRepositoryFile/Save");
            }

            delete(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DocumentRepositoryFile/Delete");
            }

            deleteMultipleFiles(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DocumentRepositoryFile/DeleteMultipleFiles");
            }    
        }
    }
    angular.module("app").service("documentRepositoryFileService", services.documentManagement.documentRepositoryFileService);
}